// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-guest-list-js": () => import("./../src/pages/guestList.js" /* webpackChunkName: "component---src-pages-guest-list-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-saasmodern-js": () => import("./../src/pages/saasmodern.js" /* webpackChunkName: "component---src-pages-saasmodern-js" */),
  "component---src-pages-table-plan-js": () => import("./../src/pages/tablePlan.js" /* webpackChunkName: "component---src-pages-table-plan-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

